import React,{useContext} from 'react';
import UserActionButton from './UserActionButton';

import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const ProfilecardOne = ({userDetails,fetchUserDetails}) => {
    const { user } = useContext(UserContext);
    const {id, name, username, profile_image,cover_photo,posts_count,friends_count,connection_status} = userDetails;
        return (
            <div className="card w-100 shadow-xss  border-0 mb-3 mt-3 pb-3">
                <div className="rounded-top card-body position-relative h150 bg-image-cover bg-image-center text-right"
                style={{backgroundImage: `url(${cover_photo ? `${process.env.REACT_APP_WEB_BASE_URL}${cover_photo}` : "https://via.placeholder.com/1200x250.png"})`}}>
                    {user && user.id === id && (
                        <Link to="/accountinformation">
                            <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight" style={{position: "absolute",top: "-15px"}}></i>
                        </Link>
                    )}
                </div>
                
                <div className="card-body d-block pt-4 text-center">
                    <figure className="avatar mt--6 position-relative  z-index-1 w100 h100  z-index-1 ms-auto me-auto">
                        <img 
                            src={profile_image ? `${process.env.REACT_APP_WEB_BASE_URL}${profile_image}` : "assets/images/user.png"} 
                            alt="avater" 
                            className="p-1 bg-white rounded-xl h100 w-100" />
                    </figure>
                    <h4 className="font-xs ls-1 fw-700 text-grey-900">{name} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">@{username}</span></h4>
                </div>
                <div className="card-body d-flex align-items-center justify-content-between ps-4 pe-4 pt-0">
                    <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2"><b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">{posts_count} </b> <p>Posts</p></h4>
                    <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2"><b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">{friends_count}</b> <p>Connections</p></h4>
                </div>
                <div className="card-body text-center ps-4 pe-4 pt-0">

                     <UserActionButton
                        connection_status = {connection_status}
                        userId = {id}
                        fetchMemberList={fetchUserDetails} 
                    /> 
                    
                   
                </div>
            </div>
        );
    
}

export default ProfilecardOne;