import React, {   Fragment } from 'react';
import Header from '../components/Header';


import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';


import Friends from '../components/Friends';


import ListPosts from './ListPosts';


const Home = () => {
   

        return (
            <Fragment>
                <div className = "main_content_blurr"> 
                <Header />
                {/* <Rightchat /> */}

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-8 col-xxl-9 col-lg-8">
                                   {/* <Createpost onPostCreated={handlePostCreated} /> */}
                                    
                                   <ListPosts />
                                </div>
                                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                    <Friends />
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Popupchat />
                <Appfooter /> 
                </div>
            </Fragment>
        );
}

export default Home;