

import pusher from '../utils/pusher'; // Your pusher.js file where Pusher is initialized
import { useEffect } from 'react';
import { useNotification } from '../context/NotificationContext'; // Import the context
import { UserContext } from '../context/UserContext';
import {useContext} from 'react';
const PusherService = () => {
  const {
    updateMessagesData,
    incrementUnreadMessages,
    incrementUnreadNotifications,
    resetUnreadMessages,
    resetUnreadNotifications,
  } = useNotification();

  const { user } = useContext(UserContext); 

  useEffect(() => {
    // Subscribe to the 'chat' channel for messages
    const chatChannel = pusher.subscribe(`chat${user && user.id}`);
    
    chatChannel.bind('message.sent', (data) => {
      
        /* alert(data); */
        
        //incrementUnreadMessages();
        // If the user is not on the message route, increment the unread messages count
      if (!window.location.pathname.includes('/messages')) {
        updateMessagesData(data.messages); 
        incrementUnreadMessages();
      } else {

        updateMessagesData(data.messages); 
        // Optionally reset unread messages if the user is already on the message screen
        resetUnreadMessages();
        // You can also handle adding the new message to the chat list here
      }
    });

    // Subscribe to the 'notification' channel for general notifications
    const notificationChannel = pusher.subscribe('notification');

    notificationChannel.bind('notification.received', (data) => {
      // If the user is not on the notifications route, increment unread notifications
      if (!window.location.pathname.includes('/notifications')) {
        incrementUnreadNotifications();
      } else {
        // Optionally reset unread notifications if the user is on the notification screen
        resetUnreadNotifications();
        // Handle appending new notifications here
      }
    });

    // Cleanup bindings on unmount
    return () => {
      chatChannel.unbind('message.sent');
      notificationChannel.unbind('notification.received');
      pusher.unsubscribe('chat');
      pusher.unsubscribe('notification');
    };

    // eslint-disable-next-line no-console
  }, [user, incrementUnreadMessages, resetUnreadMessages, incrementUnreadNotifications, resetUnreadNotifications]);

  return null; // This component doesn’t render anything
};

export default PusherService;
