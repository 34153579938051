import React, {  Fragment, useEffect, useState,useCallback,useRef } from "react";

import Header from '../components/Header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Contacts from "../components/Contacts";

import axiosInstance from '../service/api';
import { ProfileImageBox } from "../components/PostComment";
import { Link } from "react-router-dom";
import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import { timeAgo } from "../utils/helper";
//import axiosInstance,{handleImageCompression} from '../service/api';

import { useNotification } from "../context/NotificationContext";

const Chat = () => {
    const [chatId,setChatId] = useState(null);
    /* const onAfterChatInitiate = (chat_id) => {
        setChatId
    } */
        return (
            <Fragment> 
                <Header />
                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row">
                                <div className="col-xl-8 col-xxl-9 col-lg-8">
                                    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                        <div className="card-body p-3 w-100 border-0">
                                            
                                            <ChatMessage chatId = {chatId} />
 
                                        </div>    
                                    </div>
                                </div>
                                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                    <Contacts onAfterInitiateChat = {setChatId} />
                                
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>


               
            </Fragment>
        );

}


const ChatMessage = ({chatId}) => {

    const {  messages,updateMessagesData } = useNotification();

    const [page, setPage] = useState(1); // Track current page
    const [hasMore, setHasMore] = useState(true); // Track if more messages are available

    //const [messageList,setMessageList] = useState([]);
    const [currentChatDetails,setCurrentChatDetails] = useState(null);
    const chatBoxRef = useRef(null);

    const initialValues = {
        chat_message: '',
        
    };

    const chatValidationSchema = Yup.object().shape({
        chat_message: Yup.string()
            .min(2, 'Message must be at least 2 characters long')
            .max(50, 'Message must be less than 50 characters long')
            .required("Please enter message"),
        user_id: Yup.number(),
        
    });

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
        try {
            const response = await axiosInstance.post(`/chats/${chatId}/messages`, values);
            
            showSuccessMsg(response.data.message);
            
            updateMessagesData(response.data.data.messageDetails);
            resetForm();
            //getChatMessage(chatId);
        } catch (errors) {
            if (errors.response && errors.response.data) {
                let error = errors.response.data.error;
                Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
        } finally {
            setSubmitting(false);
        } 
    };


    const getChatMessage = useCallback(async (chatId,page) => {
        try {
            
            if(!chatId){ 
                return; 
            }

            const response = await axiosInstance.get(`chats/${chatId}/messages?page=${page}`);
            const { data, last_page } = response.data.data.messages;
            
            if (page >= last_page) {
                setHasMore(false); // No more pages to load
            }else{
                setHasMore(true);
            }

            setCurrentChatDetails(response.data.data.chatDetails);
            updateMessagesData(data);
             
        } catch (error) {
            console.error('Error fetching member list', error);
        }
        // eslint-disable-next-line no-console
    }, [chatId]);

    
// Scroll to the bottom of the chat box when the messageList is updated
    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);
    
    
// Load more messages on scroll to top
    const handleScroll = () => {
        if (chatBoxRef.current.scrollTop === 0 && hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
        
    };
    

    /* useEffect(() => {
        getChatMessage(chatId);
    },[chatId,getChatMessage]) */

    // Fetch messages when page or chatId changes
    useEffect(() => {
        getChatMessage(chatId, page);
    }, [chatId, page, getChatMessage]);

    useEffect(() => {
        if(!chatId){ 
            updateMessagesData([],true);
        }
        // eslint-disable-next-line no-console
    }, []);
    return (
        <> 
        {currentChatDetails && 
            <div className=" w-100 mb-2 border-bottom">
                <div className="card  border-0 ">
                    <div className="card-body d-flex p-0 ">
                        <ProfileImageBox 
                            cuserDetails={
                                {
                                    id:currentChatDetails.user_id,
                                    name:currentChatDetails.name,
                                    profile_image:currentChatDetails.profile_image
                                    }
                            }
                        />
                        <Link  to={`user-details?userId=${currentChatDetails.user_id && currentChatDetails.user_id}`}>
                            <h4 className="fw-700 text-grey-900 font-xssss mt-1 mb-1">
                                {currentChatDetails.name && currentChatDetails.name}
                                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"></span>
                            </h4>
                        </Link>
                    </div>
                </div>
            </div>
            }
        <div 
            className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg" 
            ref={chatBoxRef}
            onScroll={handleScroll}
        >
            <div className="chat-body pe-2 ">
                <div className="messages-content pb-1"> 
                {messages && messages.map((message) => (
                    <div 
                        className={`message-item ${message.self?'outgoing-message':''}`} 
                        key = {`messageList_${message.id}`}
                        >
                        <div className="message-user">
                        {message.sender && (
                            <>
                                <ProfileImageBox cuserDetails={message.sender} />
                                <div className="d-flex">
                                    <h5 className="mt-2 ">{message.sender.name}</h5>
                                    <div className="time ms-2 mt-2"><b >&#8226;</b> {timeAgo(message.created_at)}</div>
                                </div>
                            </>
                            )}
                        </div>
                        {message.attachment && 
                            <figure>
                                <img
                                    src={`${process.env.REACT_APP_WEB_BASE_URL}${message.attachment}`} 
                                    alt="avater"
                                    className="w-25 img-fluid rounded-3"
                                />
                            </figure>
                       }
                        <div className="message-wrap">{message.message}</div>
                    </div>
                ))}
                    <div className="clearfix"></div>


                </div>
            </div>
        </div>
        {currentChatDetails &&
                    <div className=" dark-bg  shadow-none theme-dark-bg" >
                        <Formik
                            initialValues={initialValues}
                            validationSchema={chatValidationSchema}
                            onSubmit={handleSubmit}
                            validateOnBlur={true}
                            validateOnChange={true}
                            
                        >
                            {({ isSubmitting, errors }) => (
                                <Form className="d-flex">
                                    <div className="pt-1 text-center col-lg-1 form-group">
                                        <i className="display1-size text-black feather-file-plus "></i>
                                    </div>
                                    <div className="col-lg-10 form-group">
                                        <Field
                                            type="text"
                                            placeholder="Start typing.."
                                            name="chat_message"
                                            className="chatFormInput w-100 rounded-xl"
                                        />

                                        <ErrorMessage
                                            name="chat_message"
                                            component="div"
                                            className="error-msg pl-2 text-danger"
                                        />
                                    </div>
                                    <div className="col-lg-1 text-right form-group">     
                                    <button
                                            type="submit"
                                            className="chatFormButton bg-current"
                                            disabled={isSubmitting}
                                        >
                                            
                                            {isSubmitting ? (
                                                <span className="spinner-border spinner-border-sm ml-2"></span>
                                            ):<i className="ti-arrow-right text-white"></i>}
                                        </button>
                                    
                                    </div>

                                    

                                    </Form>
                    )}
                </Formik>
            </div> 
}
        </>

    );

}

export default Chat;

export { ChatMessage };

