import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


import ReactTooltip from "react-tooltip";

import axiosInstance from '../service/api';
import { showSuccessMsg, showErrorMsg } from "../utils/notification";

import PostComment from './PostComment'; 
import { timeAgo } from '../utils/helper';

//import { ReactComponent as SmileyFace } from '../../assets/icons/smiley.svg';



const Postview = ({ 
                    user_liked,
                    like_type,
                    likers,
                    user, 
                    time, 
                    des, 
                    avater, 
                    postimage, 
                    postvideo, 
                    id, 
                    comment_count, 
                    like_count, 
                    love_count,
                    wow_count,
                    user_id,
                    updatePost,
                    openCommentBox,
                    toggleCommentBox,
                    check_in_address
                 }) => {
    const [tabOne, setTabOne] = useState([]);
    const [isTabOpen, setIsTabOpen] = useState(null); // Store ID of the post for which Lightbox is open
    const [tab1Index, setTab1Index] = useState(0); // Store index of the selected image

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const [replyToComment, setReplyToComment] = useState(null);

    const toggleOpen = () => setIsOpen(!isOpen);
    const toggleActive = () => setIsActive(!isActive);

    const menuClass = isOpen ? "show" : "";
    const emojiClass = isActive ? "active" : "";

    useEffect(() => {
        if (postimage) {
            setTabOne([{ postimage: postimage }]);
        }
    }, [postimage]);

    const openLightbox = (postId, index) => {
        setIsTabOpen(postId); // Set the ID of the post for which Lightbox is open
        setTab1Index(index); // Set the index of the clicked image
    };

    const closeLightbox = () => {
        setIsTabOpen(null); // Close the Lightbox by resetting the ID
    };

    const moveNext = () => {
        setTab1Index((tab1Index + 1) % tabOne.length);
    };

    const movePrev = () => {
        setTab1Index((tab1Index + tabOne.length - 1) % tabOne.length);
    };


    const postLike = (event, post_id,like_type) => {
        event.preventDefault();
        axiosInstance.post('posts/like-post', { post_id,like_type })
          .then(response => {
            // Handle successful response
            //fetchMemberList();
            updatePost(response.data.post);
            showSuccessMsg(response.data.message);
          })
          .catch(errors => {
            if (errors) {
                let error = errors.response.data.errors;
                console.log(error.connection_id); 
                Object.keys(error).map((key, index) => {
                    return key !== "debug" ? showErrorMsg(error[key][0]) : '';
                });
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
          });
      };

      const handleCommentClick = () => {
            toggleCommentBox(id);
        };

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3 Nous__Card__Post" key={id}>
            <div className="card-body p-0 d-flex ">
                <Link to={`user-details?userId=${user_id}`}>
                    <figure className="avatar me-3">
                        <img
                            src={avater ? `${process.env.REACT_APP_WEB_BASE_URL}${avater}` : "assets/images/user.png"}
                            alt="avater"
                            className="shadow-sm rounded-circle w45 h45"
                        />
                    </figure>
                </Link>
                <Link to={`user-details?userId=${user_id}`}>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                        {user} 
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{timeAgo(time)}</span>
                    </h4>
                    
                </Link>
                {check_in_address &&
                   <> {/* <>
                        <ReactTooltip id={`checkin_address_${id}`} place="top" effect="solid">
                            {check_in_address}
                        </ReactTooltip> 
                        <span data-tip data-for={`checkin_address_${id}`} className = "fw-500 text-dark font-xssss">&nbsp; is at <span className="fw-700 text-grey-900 font-xssss">{check_in_address}</span></span>
                    </> */}

                    

    <ReactTooltip id={`checkin_address_${id}`} place="top" effect="solid">
        {check_in_address}
    </ReactTooltip> 
    <span 
        data-tip 
        data-for={`checkin_address_${id}`} 
        className="fw-500 text-dark font-xssss"
        style={{ 
            display: 'inline', 
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '60%', // You can set a specific width here if needed
        }}
    >
        &nbsp; is at <span 
            className="fw-700 text-grey-900 font-xssss" 
            style={{ 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                verticalAlign: 'middle'
            }}
        >
            {check_in_address}
        </span>
    </span>
</>


                }
                <div className="ms-auto pointer">
                    <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                </div>
            </div>

            {postvideo && (
                <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                    <ReactPlayer
                        controls
                        width="100%"
                        height="auto"
                        className="react-player"
                        url={`${process.env.REACT_APP_WEB_BASE_URL}${postvideo}`}
                    />
                </div>
            )}

            <div className="card-body p-0 me-lg-5">
                <p className="fw-500 text-dark lh-26 font-xss  w-100 mb-2">{des}</p>
            </div>
            <div className="d-flex flex-wrap">
                {tabOne && tabOne.map((value, index) => (
                    <div className={`flex-fill p-1`} style={{ flexBasis: `${100 / Math.min(tabOne.length, 4)}%` }} key={index}>
                        {isTabOpen === id && (
                            <Lightbox
                                mainSrc={`${process.env.REACT_APP_WEB_BASE_URL}${tabOne[tab1Index].postimage}`}
                                onCloseRequest={closeLightbox}
                                onMovePrevRequest={movePrev}
                                onMoveNextRequest={moveNext}
                            />
                        )}

                        <div onClick={() => openLightbox(id, index)}>
                            <a href="#portfolio-details">
                                <img
                                    src={`${process.env.REACT_APP_WEB_BASE_URL}${value.postimage}`}
                                    alt="Portfolio"
                                    className="img-fluid rounded-3 w-100"
                                />
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            <div className="card-body d-flex p-1 post_card_details">
                <div
                    className={`emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2 ${emojiClass}`}
                   
                >

                <LikeButton 
                    id={id} 
                    type="like" 
                    count={like_count} 
                    tooltipId="likeButtons" 
                    iconClass="feather-thumbs-up" 
                    postLike={postLike} 
                    activeClass={like_count > 0} 
                />

                <LikeButton 
                    id={id} 
                    type="love" 
                    count={love_count} 
                    tooltipId="loveButtonIcons" 
                    iconClass="feather-heart" 
                    postLike={postLike} 
                    activeClass={love_count > 0} 
                />

                <LikeButton 
                    id={id} 
                    type="wow" 
                    count={wow_count} 
                    tooltipId="wowButtonIcons" 
                    postLike={postLike} 
                    activeClass={wow_count > 0} 
                    isTextButton={true}
                />


{/* 
                    <label data-tip data-for="likeButtons">
                        {like_count>0 && like_count}
                        &nbsp; <i  
                            onClick={(event) => postLike(event, id, 'like')} 
                            className={`pointer feather-thumbs-up text-white ${like_type === 'like' ? 'bg-red-gradiant' : 'bg-grey-faded'} me-1 btn-round-xs font-xss`}
                        ></i>

                    </label>
                    <ReactTooltip id="likeButtons" place="top" effect="solid">
                        Like
                    </ReactTooltip> 
                    <label data-tip data-for="loveButtonIcons">
                    {love_count>0 && love_count} 
                        &nbsp; <i 
                            onClick={(event) => postLike(event,id,'love')} 
                            className={`pointer feather-heart text-white ${like_type === 'love' ? 'bg-red-gradiant' : 'bg-grey-faded'} me-1 btn-round-xs font-xss`}></i>
                    </label>
                    <ReactTooltip id="loveButtonIcons" place="top" effect="solid">
                        Love
                    </ReactTooltip>
                    <label  onClick={(event) => postLike(event,id,'wow')} data-tip data-for="wowButtonIcons" className = "pointer  me-2 ">
                        {wow_count>0 && wow_count} &nbsp;<span className={`fw-700 font-xsss ${like_type === 'wow' && 'text-orange'}`}>
                            Wow!
                        </span>
                        </label>
                    <ReactTooltip id="wowButtonIcons" place="top" effect="solid">
                        Wow
                    </ReactTooltip> */}
                 </div>
                 <a 
                    href="javascript:void(0)"
                 onClick={handleCommentClick}  className="d-flex pointer align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
                    <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
                    <span className="d-none-xss">{comment_count>0 && comment_count} Comment</span>
                </a>
    
            </div>

            <div className="card-body p-0 d-flex">
                {/* <PostComment /> */}

                {/* Comment Box */}
                {openCommentBox === id && (
                    <PostComment postId={id} replyToComment={replyToComment} setReplyToComment={setReplyToComment} updatePost={updatePost} />
                )}
            </div>
        </div>
    );
};


const LikeButton = ({ id, type, count, tooltipId, iconClass, postLike, activeClass, isTextButton }) => (
    <>
        <label data-tip data-for={tooltipId} onClick={(event) => postLike(event, id, type)} className="pointer ">
            {count > 0 && count}
            &nbsp;
            {isTextButton ? (
                <span className={`fw-700 font-xsss ${activeClass && 'text-orange'}`}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}!
                </span>
            ) : (
                <i
                    className={`pointer ${iconClass} text-white ${activeClass ? 'bg-red-gradiant' : 'bg-grey-faded'} me-1 btn-round-xs font-xss`}
                ></i>
            )}
        </label>
        <ReactTooltip id={tooltipId} place="top" effect="solid">
            {type.charAt(0).toUpperCase() + type.slice(1)}
        </ReactTooltip>
    </>
);



export default Postview;

export {LikeButton};
