import React,{useState,useEffect,useCallback,useContext} from 'react';
import axiosInstance from '../service/api';
import { Link } from 'react-router-dom';
import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import { UserContext } from '../context/UserContext';



const Contacts = (props) => {

    const [contactList, setContactList] = useState([]);
    
    const { user } = useContext(UserContext);
    const fetchContactList = useCallback(async () => {
        try {
            const response = await axiosInstance.get('chats/get-chats');
            setContactList(response.data.data.data);
        } catch (error) {
            console.error('Error fetching contact list', error);
        }
    }, []);

    /* const initiateChat = async (userId) => {
        
        console.log(userId); 
    } */

    const initiateChat = (event,user_id, chat_id) => {
        event.preventDefault();
        if(chat_id){
            props.onAfterInitiateChat(chat_id);
        }else{
            axiosInstance.post('chats/create-chat', { group_name:'',participants:[user.id,user_id],type:'direct' })
            .then(response => {
                // Handle successful response
                //fetchContactList();
                const { data } = response.data; 
                props.onAfterInitiateChat(data.chat_id); 
                const updatedContactList = contactList.map(chat => 
                    chat.user_id === data.user_id ? { ...chat, ...data } : chat
                  );
                //const updatedContactList = contactList.find(chat=>chat.user_id === data.user_id); 
                setContactList(updatedContactList); 
                showSuccessMsg(response.data.message);
            })
            .catch(errors => {
                if (errors) {
                    let error = errors.response.data.errors;
                    console.log(error.connection_id); 
                    Object.keys(error).map((key, index) => {
                        return key !== "debug" ? showErrorMsg(error[key][0]) : '';
                    });
                } else {
                    showErrorMsg('An unexpected error occurred. Please try again.')
                }
            });
        }
      };

    

    useEffect(() => {
        fetchContactList();
    }, [fetchContactList]);

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex align-items-center p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">Confirm Friend</h4>
                    <Link to={`friends`} className="fw-600 ms-auto font-xssss text-black">See all</Link>
                </div>
                {contactList.map((value , index) => (

                <div key={index} className="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3 mb-3">
                        <Link  to = {`user-details?userId=${value.user_id}`}>
                            <figure className="avatar me-2 mb-0">
                                {/* <img src={`assets/images/${value.profile_image	}`} alt="avater" className="shadow-sm rounded-circle w45" /> */}
                                <img src={value.profile_image?`${process.env.REACT_APP_WEB_BASE_URL}${value.profile_image}`:"assets/images/user.png"} alt="avater" className="shadow-sm rounded-circle w45 h45" />
                            </figure>
                        </Link>
                        <Link  to = {`user-details?userId=${value.user_id}`}>
                            <h4 className="fw-700 text-grey-900 font-xssss mt-2">{value.name} {/* <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{value.friend} mutual friends</span> */}</h4>
                        </Link>
                    
                    <a href="javascript;"
                        onClick={(event) => initiateChat(event,value.user_id,value.chat_id)} 
                        
                        className="pointer btn-round-sm bg-white ms-auto mt-2" >
                        <span className="feather-chevron-right font-xss text-grey-900"></span>
                    </a>
                </div>

                ))}

                
            </div>
        );
}

export default Contacts;