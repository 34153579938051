import React, { useState,useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import axiosInstance from '../service/api';



const Profilephoto = ({userId}) => {
    const [tab1, setTab1] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [TabOne,setTabOne] = useState(null);

    const [userImageLimit, setUserImageLimit] = useState(6);
    const [showLoadMoreUserImage, setShowLoadMoreUserImage] = useState(true);

    const handleLoadMore = () => {
        setUserImageLimit(userImageLimit + 6);
        setShowLoadMoreUserImage(TabOne.length >= userImageLimit + 6);

        console.log("Image lendth:",userImageLimit,"showLoadMoreUserImage:",showLoadMoreUserImage,"Total Length:",TabOne.length); 
      };

    const fetchUserImages = async () => {
        try {
          const response = await axiosInstance.get(`/file-manager/get-user-images/${userId}`); 
          //const {user:{data}} = response; `/user/posts?page=${page}&per_page=${limit}`
          
          
          
          const { data } = response.data;
         
          setTabOne(data);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };
    
      useEffect(() => {
            fetchUserImages();
            // eslint-disable-next-line
        }, []); // Empty dependency array ensures it runs on every mount, including page refresh

        useEffect(() => {
            if (userId) {
                fetchUserImages();
            }
            // eslint-disable-next-line
        }, [userId]); // Also fetch images if userId changes



    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Photos</h4>
               {/*  <a href="/home" className="fw-600 ms-auto font-xssss text-primary">See all</a> */}
            </div>
            <div className="card-body d-block pt-0 pb-2">
                <div className="row ps-3 pe-3">
                
                    
                    {TabOne && TabOne.slice(0, userImageLimit).map((value, index) => (
                        <div className="col-6 mb-1 p-1" key={index}>
                            {isOpen && (
                                <Lightbox
                                    mainSrc={`${process.env.REACT_APP_WEB_BASE_URL}${TabOne[tab1].file.url}`}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() => 
                                        setTab1((tab1 + TabOne.length - 1) % TabOne.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setTab1((tab1 + 1) % TabOne.length)
                                    }
                                />
                            )}

                            <div onClick={() => { setIsOpen(true); setTab1(index); }}>
                                <a href="#portfolio-details">
                                    <img
                                        src={`${process.env.REACT_APP_WEB_BASE_URL}${value.file.url}`}
                                        alt="Portfolio"
                                        className="img-fluid rounded-3 h100 w-100"
                                    />
                                </a>
                            </div>
                        </div>
                    ))}

                    
                        
                    
                </div>
                {showLoadMoreUserImage  && (
                    <div className="row ps-3 pe-3">
                        <div className="col-12 mb-1 p-1">
                            <button 
                                 
                                className="border-0 p-2 lh-20 w-100 bg-black text-white text-center font-xssss fw-600 ls-1 rounded-xl d-inline-block"
                                onClick={handleLoadMore}>Continue</button>
                        </div>
                    </div>
                )}
            </div>
            {/* <div className="card-body d-block w-100 pt-0">
                <a href="/home" className="p-2 lh-28 w-100 d-block bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl">
                    <i className="feather-external-link font-xss me-2"></i> More
                </a>
            </div> */}
        </div>
    );
};

export default Profilephoto;
