import { createContext, useContext, useState } from 'react';

// Create Notification Context
const NotificationContext = createContext();

// Custom hook to use Notification context
export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  // Global state for unread messages and notifications

  const [messages, setMessages] = useState([]);

  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  // Methods to update unread counts globally
  const incrementUnreadMessages = () => {
    setUnreadMessageCount(prev => prev + 1);
  }

  /* const updateMessagesData = (data) => {
    setMessages((prevMessages) => [...data, ...prevMessages]);
    
    
  }
 */
  const updateMessagesData = (data,reset=false) => {
    
    if(reset){
      setMessages([]);
      return; 
    }
    // Check if data is an array, if not, treat it as a single message object
      if (Array.isArray(data)) {
          setMessages((prevMessages) => [...data.reverse(), ...prevMessages]);
      } else {
          setMessages((prevMessages) => [...prevMessages,data]); // If it's a single object, append it
      }
  }
  

  const resetUnreadMessages = () => setUnreadMessageCount(0);

  const incrementUnreadNotifications = () => setUnreadNotificationCount(prev => prev + 1);
  const resetUnreadNotifications = () => setUnreadNotificationCount(0);

  return (
    <NotificationContext.Provider value={{
        messages,
        updateMessagesData,
      unreadMessageCount,
      unreadNotificationCount,
      incrementUnreadMessages,
      resetUnreadMessages,
      incrementUnreadNotifications,
      resetUnreadNotifications,
    }}>
      {children}
    </NotificationContext.Provider>
  );
};
